<template>
  <div>
    <div v-show="showTargetLetters">
      <b> Solving for letters: </b>{{ enteredByUser }}
    </div>
    <form @submit.prevent="onSubmit">
      <div v-if="errors.length">
        <b> Please correct the following error(s):</b>
        <ul class="list-group">
          <li
            class="text-primary"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error }}
          </li>
        </ul>
      </div>

      <label for="letters" class="text-muted"
        >Enter up to 15 characters: enter up to 2 wildcards with a "?":</label
      >

      <span class="form-inline my-2 my-lg-0">
        <input
          placeholder="abc"
          id="letters"
          v-model="letters"
          class="mr-sm-2 form-control clearable"
        />

        <input
          class="btn btn-primary btn-lg"
          type="submit"
          value="Find Words"
        />
      </span>
      <br />
      <div>
        <div
          v-for="(wordList, index) in allFoundWords.matchingWords"
          v-bind:key="index"
        >
          <span class="navbar navbar-expand-lg navbar-dark bg-primary">
            {{ wordList.category }} Letter Words
          </span>

          <ul>
            <li v-for="(word, index) in wordList.words" v-bind:key="index">
              <Word :word="word" />
            </li>
          </ul>
        </div>

        <div v-if="noResultsMessage" class="text-primary">
          No results found
        </div>

        <br />

        <p>
          Welcome to wordlister.org!
        </p>
        <p>
          Wordlister.org is a fast & friendly word search for Scrabble, Words
          With Friends, and other word games.
        </p>
        <p>
          Give it a try! Enter up to 15 characters, including up to two (2)
          wildcards. NOTE: Wildcards are represented by a question mark (?).
        </p>

        <div class="alert alert-dismissible alert-warning">
          <button type="button" class="close" data-dismiss="alert">
            &times;
          </button>

          <h4 class="text-left">Other Player Resources</h4>
          <p class="text-left">
            <router-link class="alert-link" to="JWords" rel="JWords">
              All 'J' Words, 7 letters or less</router-link
            >
            <br />
            <router-link class="alert-link" to="Qwords" rel="Qwords">
              All 'Q' Words, 7 letters or less</router-link
            >
            <br />

            <router-link class="alert-link" to="ZWords" rel="ZWords">
              All 'Z' Words, 7 letters or less</router-link
            >

            <br />
            <router-link
              class="alert-link"
              to="TwoLetterWords"
              rel="TwoLetterWords"
            >
              All 2-Letter Words</router-link
            >
            <br />
            <router-link
              class="alert-link"
              to="ThreeLetterWords"
              rel="ThreeLetterWords"
            >
              All 3-Letter Words</router-link
            >
          </p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src

//NOTE: The bootswatch theme used in this project is:
//'journal' found here: https://bootswatch.com/journal/?optionsRadios=option1

import wordListerService from "../../services/wordListerService";
import Word from "@/components/Word.vue";
import NProgress from "nprogress";

export default {
  name: "home",
  components: {
    Word
  },
  data() {
    return {
      errors: [],
      letters: [],
      allFoundWords: [],
      noResultsMessage: null,
      enteredByUser: null,
      AllWordsInArray: [],
      AllWordsInArraysCombinedTogether: [],
      rawResultsFromQuery: null,
      CharVersionOfUpperCaseLetters: [],
      UpperCaseLettersRearranged: [],
      UpperCaseLettersRearrangedForAPI: [],
      testingWordList: [],
      showTargetLetters: false
    };
  },
  methods: {
    format(letters) {
      return `<strong> ${letters} </strong>`;
    },

    onSubmit() {
      //flush all variables for a new query
      this.showTargetLetters = false;
      this.errors = [];
      this.allFoundWords = [];
      this.noResultsMessage = null;
      this.enteredByUser = this.letters;
      this.rawResultsFromQuery = null;
      this.CharVersionOfUpperCaseLetters = [];
      this.UpperCaseLettersRearranged = [];
      this.UpperCaseLettersRearrangedForAPI = [];
      this.AllWordsInArraysCombinedTogether = [];

      //trim the letters
      this.letters = this.letters.trim().replace(/ /g, "");

      //check for data entry errors
      this.checkForDataEntryErrors(this.letters);

      if (!this.errors == false && this.errors.length == 0) {
        this.showTargetLetters = true;
        var UpperCaseLetters = this.letters.toUpperCase();
        this.CharVersionOfUpperCaseLetters = UpperCaseLetters.split("");
        this.checkForWildCards(this.CharVersionOfUpperCaseLetters);
        this.findMatches();
      } else {
        //if there are errors, do nothing
      }
    },

    findMatches() {
      NProgress.start();
      wordListerService
        .findWords(this.UpperCaseLettersRearrangedForAPI)
        .then(response => {
          NProgress.done();
          this.allFoundWords = response.data;

          if (JSON.stringify(this.allFoundWords) == '{"matchingWords":[]}') {
            this.noResultsMessage = "No matching words found";
          }
        })
        .catch(error => this.errors.push(error));
    },

    checkForDataEntryErrors(lettersToCheck) {
      if (
        !lettersToCheck ||
        lettersToCheck.length == 1 ||
        lettersToCheck.length == 0
      ) {
        this.errors.push("At least two letters required");
      }
      //if there ARE letters entered, check for how many, and if there are illegal chars:
      else {
        if (lettersToCheck.length > 15) {
          this.errors.push("Only 15 characters allowed.");
        }

        var charCharacters = /^[?A-Za-z]+$/;
        if (!lettersToCheck.match(charCharacters)) {
          this.errors.push(
            "Only alpha characters and up to two wildcards - represented by individual question marks (?) - are allowed."
          );
        }

        if (this.checkForMoreThanTwoWildCards(lettersToCheck)) {
          this.errors.push("Only Two (2) wildcards are allowed.");
        }
      }
    },

    checkForMoreThanTwoWildCards(lettersToCheck) {
      var countWildCards = 0;
      for (var a = 0; a < lettersToCheck.length; a++) {
        if (lettersToCheck[a] == "?") {
          countWildCards = countWildCards + 1;
        }
      }
      if (countWildCards > 2) {
        return true;
      } else {
        return false;
      }
    },

    checkForWildCards(CharVersionOfUpperCaseLetters) {
      var localVersionOfEnteredChars = CharVersionOfUpperCaseLetters;
      var newArrayOfChars = new Array();
      var newArrayOfCharsForAPI = new Array();
      var questionMarks = new Array();
      var astersisksForAPI = new Array();

      var i;
      for (i = 0; i < localVersionOfEnteredChars.length; i++) {
        if (localVersionOfEnteredChars[i] == "?") {
          questionMarks.push(localVersionOfEnteredChars[i]);
          astersisksForAPI.push("*");
        } else {
          newArrayOfChars.push(localVersionOfEnteredChars[i]);
          newArrayOfCharsForAPI.push(localVersionOfEnteredChars[i]);
        }
      }
      var combinedArray = new Array();
      var combinedArrayForAPI = new Array();
      if (questionMarks) {
        //combine the two arrays
        combinedArray = newArrayOfChars
          .concat(questionMarks)
          .join("")
          .split("");

        combinedArrayForAPI = newArrayOfCharsForAPI
          .concat(astersisksForAPI)
          .join("")
          .split("");
      } else {
        combinedArray = newArrayOfChars.join("").split("");
        combinedArrayForAPI = newArrayOfCharsForAPI.join("").split("");
      }

      //create a string for the rearranged letters
      var chosenLettersString = combinedArray.join("");
      var chosenLettersStringForAPI = combinedArrayForAPI.join("");

      //reset the array variables
      questionMarks = [];
      newArrayOfChars = [];
      combinedArray = [];

      astersisksForAPI = [];
      newArrayOfCharsForAPI = [];
      combinedArrayForAPI = [];

      //assign the value of the string to instance variables
      this.UpperCaseLettersRearranged = chosenLettersString;
      this.UpperCaseLettersRearrangedForAPI = chosenLettersStringForAPI;
    }
  }
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.clearable {
  background: #fff
    url(https://bijudesigner.com/blog/wp-content/uploads/2015/06/download.gif)
    no-repeat right -10px center;
  padding: 3px 18px 3px 4px; /* Use the same right padding (18) in jQ! */
  transition: background 0.4s;
}
.clearable.x {
  background-position: right 5px center;
}
.clearable.onX {
  cursor: pointer;
}
</style>
